// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // base_url: 'http://tpv-rf.com',
  // base_url: 'http://atpv.com/tpv-rf/public',
  // base_url: 'http://localhost/tpv-rf/public',
  // base_url: 'http://localhost/tpv-local/public',
  // base_url: 'http://gestionangular.com/tpv-rf/public',
  // base_url: 'http://localhost/ddtpv-rf/public',
  // base_url: 'http://gestionangular.es/tpv/public',
  // base_url: 'https://desarrollo.gestionangular.es/tpv/public',
  // base_url: 'http://minas/tpv-rf/public',


  /* -------------------------------------------------------------------------- */
  /*                             BASES    CLIENTES                              */
  /* -------------------------------------------------------------------------- */
  // base_url: 'https://bcbar.gestionangular.es/tpv/public',
  // base_url: 'https://braischas.gestionangular.es/tpv/public',
  // base_url: 'https://capricho.gestionangular.es/tpv/public',
  // base_url: 'https://evo.gestionangular.es/tpv/public',
  // base_url: 'https://loxo.gestionangular.es/tpv/public',
  base_url: 'https://metalrod.gestionangular.es/tpv/public',
  // base_url: 'https://nevedas.gestionangular.es/tpv/public',
  // base_url: 'https://penas.gestionangular.es/tpv/public',
  // base_url: 'https://sebio.gestionangular.es/tpv/public',
  // base_url: 'https://silva.gestionangular.es/tpv/public',
  // base_url: 'https://valino.gestionangular.es/tpv/public',
  // base_url: 'https://veproga.gestionangular.es/tpv/public',
  
  
  /* -------------------------------------------------------------------------- */
  /*                              BASES DINAHOSTING                             */
  /* -------------------------------------------------------------------------- */
  // base_url: 'https://bcbar.gestionangular.net/tpv/public',
  // base_url: 'https://capricho.gestionangular.net/tpv/public',




  marcaFinal: 'final', //Es la palabra clave para que codIgniter ignore los parámetros 'hasta'. Lógicamente también debe estar definida como constante en codeIgniter.
  clientes: [],
  articulos: [],
  noImage: 'assets/noimage.png',
  almacenDefecto: 1,
  tarifaDefecto: 1,
  timeOutDefecto: 2000,
  activarVoz: false,
  gmt: "",


  /* -------------------------------------------------------------------------- */
  /*                               CONFIGURACIONES                              */
  /* -------------------------------------------------------------------------- */

  activarAvisoFacturasPeriodicas: false,
  activarAvisoStockMinimo: false,
  activarFraseLogin: false,
  bancoEnFormaPago: false,
  bloqueoCampos: false,
  loginHastaDomingo: false,
  mostrarHistorialUrl: false,
  nivel: 99,
  timeOutImpresion: 1000,


  /* -------------------------------------------------------------------------- */
  /*                                  ARTICULOS                                 */
  /* -------------------------------------------------------------------------- */
  
  activarCodigosArticuloAlt: false,
  activarTarifas: false,
  activarTarifasCompuestas: false,
  activarTrazabilidad: false,
  activarUbicacion: false,
  actualizarPrecioCoste: '',
  actualizarPrecioTactil: false,
  actualizarPvpDesdeEntrada: false,
  calculoMargenesXCompra: false,
  codigoArticuloAutomatico: false,
  crearArticuloDesdeEntrada:false,
  crearArticuloDesdeOT:false,
  descontarTrazasAutomatico: false,
  descripcionArticuloLarga: false,
  mostrarTodosPrecios: false,
  permitirModificarCodigoArticulo: false,
  precioBuscadorArticulos: false,
  precioConIVA: false,
  precioCosteIVANoIncluido: false, //Por defecto se tomará el iva incluido del artículo. En este caso siempre el coste será siempre sin iva.


  /* -------------------------------------------------------------------------- */
  /*                                  CLIENTES                                  */
  /* -------------------------------------------------------------------------- */

  activarClienteFijo: false,
  codigoClienteAutomatico: false,
  ordenComboClientes: "nombreFiscal",



  /* -------------------------------------------------------------------------- */
  /*                                  FAMILIAS                                  */
  /* -------------------------------------------------------------------------- */

  familiasArbol : false,

  /* -------------------------------------------------------------------------- */
  /*                                   MODULOS                                  */
  /* -------------------------------------------------------------------------- */

  moduloAgro: false,
  moduloAlmacen: false,
  moduloBar: false,
  moduloCaja: false,
  moduloCaja2: false,  //La caja 2 activará la serie 2 Tickets se usará para bares, peluquerías, etc.
  moduloCamping: false,
  moduloCompras: false,
  moduloContabilidad: false,
  moduloFabricacion: false,
  moduloLogistica: false,
  moduloOcr: false,
  moduloPeluqueria: false,
  moduloTaller: false,
  moduloTienda: false,
  moduloVentas: false,
  // token: '',
  // usuario: '',


  /* -------------------------------------------------------------------------- */
  /*                      TICKETS FACTURAS PRESUPUESTOS Y ALBARANES             */
  /* -------------------------------------------------------------------------- */
  
  activarCajaEnFacturas: false,
  activarCobrosEnFacturas: false,
  activarCodigoBarrasEnTactil: false,
  activarDescuentosVolumen: false,
  activarDivisas: false,
  activarGrabacionDirectaLineas: false,
  activarHistorialLineas: false,
  activarPrecioConIVAenFormularios: false,
  activarUnaSolaCaja: false,
  diasDefectoConsultas: 7,
  fechaLineaTicket: false,
  fechaUltimaFactura: false,  //En facturación de albaranes cogerá la última fecha facturada y no la del día.
  imprimirAlGrabar: false,
  imprimirModoViejo: false,
  imprimirTicket: false,
  iniciarCombosBlanco: false,
  noUnirLineas: false,
  nuevoAlGrabar: true,
  ocultarComboArticulos: false,
  permitirModificarFacturas: false,
  precioUnidadTicket: false,
  precioxTemporada: false,
  reportEnSerie: false, // Nos indica si el nombre del report está en la tabla serie en lugar de en la tabla empresa.
  siempreAbrirCajonAlGrabar: false,
  ticketSiCliente9999: false, // La factura cambiará a serie ticket automáticamente si el el cliente es 9999

  /* -------------------------------------------------------------------------- */
  /*                                  VEHÍCULOS                                 */
  /* -------------------------------------------------------------------------- */

  crearVehiculoDesdeOT:false,

};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


